import React from "react";
import Login from "./login";
// import Banner from "../components/section/banner";
// import AboutSection from "../components/section/about";
// import PlanSection from "../components/section/plans";
// import WhySection from "../components/section/why";
// import IPSection from "../components/section/ip-section";
// import Peer from "../components/section/provider";
// import FaqSection from "../components/section/faq";
// import Seo from "../components/section/seo";
// import Main from "../components/main";

// export default function IndexPage() {
//   return (
//     <>
//       <Main>
//         <Seo title="GetGud - Internet Provider">
//           <meta name="description" content="GetGud is an Internet Service Provider build for gaming performance" />
//         </Seo>
//         <Banner />
//         <AboutSection />
//         <PlanSection />
//         <WhySection />
//         <IPSection />
//         <Peer />
//         <FaqSection />
//       </Main>
//     </>
//   );
// }

export default Login;